
  export const style={
    inpuStyle:{
      colorInput:"#5C42DB"
    },
    url:{
      urlClient:"https://ecom.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/logo_client_ecom.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: true,
       autoRegister: true,
       recaptchaVersion : "V1"
    },
    CurrencyName:{
      name:"Watt/Watts"
    },
    teamName:{
      name:"Ecom"
    },
    hasToken: true,
    hostHeader: "ecom"
  }
